// @file Creating common classes used for network requests on the padlet page
/* eslint-disable no-return-await, no-template-curly-in-string */
import { apiUrlInterpolate } from '@@/bits/api_url_interpolate'
import { asciiSafeStringify } from '@@/bits/json_stringify'
import { fetchJson } from '@@/surface/api_fetch'
import type { FetchOptions } from '@padlet/fetch'
import { HTTPMethod } from '@padlet/fetch'

class FetchableObject {
  public static get url(): string {
    return ''
  }

  public static get fetchUrl(): string {
    return ''
  }

  public static buildUrl(url, options): string {
    return apiUrlInterpolate(url, options)
  }

  public static async readAll(options, fetchOptions = {}): Promise<any> {
    return await fetchJson(this.buildUrl(this.fetchUrl, options), {
      method: HTTPMethod.get,
      ...fetchOptions,
    })
  }

  public static async read(obj, fetchOptions = {}): Promise<any> {
    return await fetchJson(`${this.url}/${obj.id}`, {
      method: HTTPMethod.get,
      ...fetchOptions,
    })
  }

  public static async update(obj, fetchOptions = {}): Promise<any> {
    return await fetchJson(`${this.url}/${obj.id}`, {
      method: HTTPMethod.put,
      body: asciiSafeStringify(obj),
      ...fetchOptions,
    })
  }

  public static async create(obj, fetchOptions = {}): Promise<any> {
    return await fetchJson(`${this.url}`, {
      method: HTTPMethod.post,
      body: asciiSafeStringify(obj),
      ...fetchOptions,
    })
  }

  public static async delete(obj, fetchOptions = {}): Promise<any> {
    return await fetchJson(`${this.url}/${obj.id}`, {
      method: HTTPMethod.delete,
      ...fetchOptions,
    })
  }
}

export interface VersionedFetchOptions extends FetchOptions {
  apiVersion?: number
}

export default FetchableObject
